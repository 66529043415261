<template>
  <v-app
    id="app"
    class="app"
    :class="[
      {'white': isWhite},
      {'jp': $route.params.language === 'jp'}]"
    v-show="!$root.platform.prerenderer && isReady">

    <!--<div v-if="!isPasswordCorrect" class="password">
      <Password @onPassword="isPasswordCorrect = true"></Password>
    </div>-->
    <div>
      <v-main>
        <transition mode="out-in">
          <router-view v-if="isReady" />
        </transition>
      </v-main>
      <Footer></Footer>
      <Header></Header>
      <VersionInfo
        v-if="$root.process.env.SERVICE_STAGE !== 'production'"
        :version="$root.process.env.GIT_VERSION"
        :commit-hash="$root.process.env.GIT_COMMIT_HASH"
        />
    </div>
  </v-app>
</template>

<script>
// import Password from './organisms/Password.vue'
import Footer from './organisms/Footer.vue'
import Header from '@/organisms/Header.vue'
import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import shareImage from '@/assets/img/share.jpg'
let initializedModernizr = false

export default {
  name: 'App',

  components: {
    // Password,
    Footer,
    Header,
    VersionInfo
  },

  data: function () {
    return {
      // this flag is used to show the app once the preloading
      // (if present) is finished
      isReady: false,
      password: '',
      isPasswordCorrect: false,
      isWhite: false
    }
  },

  mounted: function () {
    // set this.isReady = true
    // when all stuff that needs to be loaded for the app is loaded
    // if you need to preload stuff, delete this line and set to true later
    this.isReady = true
  },
  updated () {
    // if not prerenderer
    if (!this.$root.platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      // window.Modernizr.addAsyncTest('avif', this.testAvif)
      for (const key in this.$root.platform) {
        window.Modernizr.addTest(key, () => {
          return this.$root.platform[key]
        })
      }
    }
  },
  watch: {
    isReady: function (value) {
      // hide / show preloader
      this.$root.preloader.visible = !value
    },
    $route () {
      if (this.$route.query && this.$route.query.white) {
        this.isWhite = true
      }
    }
  },

  metaInfo: function () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: ' ',
      // all titles will be injected into this template
      titleTemplate: 'Le Tinte',
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '' },
        { name: 'twitter:creator', content: '' },
        // generic description
        { vmid: 'description', name: 'description', content: 'Le Tinte - stylem italy website' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/' + this.$route.fullPath },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'stylem' },
        { vmid: 'ogdescription', property: 'og:description', content: 'The brand name represents our idea to color the lifestyles of people throughout the world.' },
        { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + shareImage },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '630' }
      ]
    }
  }
}
</script>

<style lang="scss">
.jp {
  font-family: 'MS PGothic', Osaka, Arial, sans-serif !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'MS PGothic', Osaka, Arial, sans-serif !important;
  }
}

body,
html,
.theme--light.v-application {
  background: rgb(2, 2, 2);
  background: linear-gradient(10deg, rgba(10, 10, 10, 1) 0%, rgba(36, 36, 36, 1) 35%, rgba(23, 23, 23, 1) 100%);
  font-family: 'Raleway', sans-serif;
  color: $c-white !important;

  &.white {
    background: #fff;
    color: $c-black !important;

    a {
      text-decoration: none;
      color: $c-black;
    }

    h1,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    a {
      font-weight: 300;
    }
  }

  a {
    text-decoration: none;
    color: $c-white;
  }
}

.font-script {
  font-family: Arial, cursive;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Work Sans', sans-serif;
}

h1,
h1 span {
  font-size: 72px;
  letter-spacing: 8px;
  font-weight: 100;
  line-height: 1;

  @include breakpoint('sm-and-down') {
    font-size: 64px;
  }
}

h2,
h2 span {
  font-size: 48px;
  letter-spacing: 8px;
  font-weight: 600;
  line-height: 1;

  @include breakpoint('sm-and-down') {
    font-size: 34px;
    letter-spacing: 4px;
  }
}

h3 {
  font-size: 36px;
  letter-spacing: 8px;
  font-weight: 200;
  line-height: 1;

  @include breakpoint('sm-and-down') {
    font-size: 28px;
  }
}

h4,
h4 span {
  font-size: 30px;
  letter-spacing: 1.2px;
  font-weight: 400;

  @include breakpoint('sm-and-down') {
    font-size: 24px;
  }
}

h5,
h5 span {
  font-size: 24px;
  letter-spacing: 1.2px;
  font-weight: 400;

  @include breakpoint('sm-and-down') {
    font-size: 18px;
  }
}

h6,
h6 span {
  font-size: 18px;
  letter-spacing: 1.2px;
  font-weight: 100;

  @include breakpoint('sm-and-down') {
    font-size: 14px;
  }
}

.relative {
  position: relative;
}

.small-underline {
  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 20px;
    background-color: $c-white;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.medium-line {
  position: relative;
  height: 20px;
  width: 40px;

  &::after {
    content: '';
    height: 1px;
    width: 40px;
    background-color: $c-white;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

span,
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 1.2px;
  @include breakpoint('sm-and-down') {
    font-size: 12px;
  }
}

.bold {
  font-weight: 600;
}

.light {
  font-weight: 100;
}

span {
  font-size: 14px;
  letter-spacing: 2px;
}

.lowercase {
  text-transform: none;
}

.c-white {
  color: $c-white;
}

.c-brown {
  color: $c-brown;
}

.bg-mobile-footer {
  width: 100vw;
  background-color: $c-black;
  position: fixed;
  z-index: 1;
  height: 85px;
  bottom: 0;
  display: none;

  @include breakpoint('sm-and-down') {
    display: block;
  }
}

.fav-button {
  position: fixed;
  left: 30px;
  bottom: 8vh;
  cursor: pointer;
  z-index: 2;

  @include breakpoint('sm-and-down') {
    bottom: 30px;
  }

  .star {
    width: 15px;
    vertical-align: middle;
    display: inline-block;
  }

  p {
    display: inline-block;
    letter-spacing: 3px;
  }
}

.logo-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 180px;
  height: auto;
  z-index: 2;

  @include breakpoint('sm-and-down') {
    display: block;
  }
}

.inline-block {
  display: inline-block;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.password-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint('sm-and-down') {
    width: 70vw;
  }

  label {
    letter-spacing: 1px;
  }

  input {
    background-color: #222;
    color: $c-brown;

    @include breakpoint('sm-and-down') {
      width: 70vw;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-out;
}

.v-enter,
.v-leave-active {
  opacity: 0;
}
</style>
