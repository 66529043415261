import packageConfig from '../package.json'
import Platform from '@monogrid/js-utils/lib/Platform'
import availableLanguages from '@/assets/data/availableLanguages.json'

//
// only pages should be imported and used here
// http://bradfrost.com/blog/post/atomic-web-design/
//

let routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/HomePage')
  },
  {
    path: '/company/',
    name: 'Company',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/CompanyPage')
  },
  {
    path: '/identity/',
    name: 'Identity',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/IdentityPage')
  },
  /* {
    path: '/history/',
    name: 'History',
    component: () => import('./pages/HistoryPage')
  }, */
  {
    path: '/collection/',
    name: 'Collection',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/CollectionPage')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/ContactPage')
  },
  {
    path: '/certification',
    name: 'Certification',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/CertificationPage')
  },
  {
    path: '/document/:docname?',
    name: 'Document',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/DocumentPage')
  }
]

//
// System routes
//
if (!packageConfig.multilanguage || packageConfig.multilanguage === 'false') {
  // no multilanguage
  routes = routes.concat([
    {
      // matches /anyroute (if not found) and redirects it to /
      path: '*',
      redirect: '/'
    }
  ])
} // eslint-disable-line brace-style
else {
  //
  // multilanguage system
  // don't touch this :)
  //

  // create a regex capturing group like en|it|es|ru
  // based on availableLanguages
  let possibleLanguages = ''
  Object.keys(availableLanguages).forEach((lang) => {
    possibleLanguages += lang + '|'
  })
  possibleLanguages = possibleLanguages.substring(0, possibleLanguages.lastIndexOf('|'))

  // determine user browser language
  let userLanguage
  if (!Platform.prerenderer) {
    userLanguage = (window.navigator.languages ? window.navigator.languages[0] : null) || window.navigator.userLanguage || window.navigator.language || 'en'
    // strip down '-US' part (as in 'en-US' or similar) if present
    userLanguage = userLanguage.split('-')[0]
  } else {
    userLanguage = 'en'
  }

  const getValidLanguage = () => {
    // if it exist, set user language as default
    if (availableLanguages[userLanguage]) {
      return userLanguage
    }
    // set first language as default
    return Object.keys(availableLanguages)[0]
  }

  routes.forEach(route => {
    const oldPath = route.path
    // modify routes to require a valid language before it
    route.path = `/:language(${possibleLanguages})${route.path}`

    if (Platform.prerenderer) {
      // create duplcate routes for pre-renderer with no language
      // it needs them in order to avoid redirection from / to /en (for example)

      // TODO: Deep clone of nested routes?
      const dup = {}
      Object.keys(route).forEach(key => {
        dup[key] = route[key]
      })
      dup.name = route.name + '-prerenderer'
      dup.path = oldPath
      routes.push(dup)
    }
  })
  // append utility routes for redirection
  routes = routes.concat([
    {
      // matches /validlanguage/invalidroute and redirects it to /validlanguage
      path: `/:language(${possibleLanguages})/*`,
      redirect: to => {
        return `/${getValidLanguage()}`
      }
    },
    {
      // matches /invalidlanguage(2 chars)/anyroute and redirects it to /validlanguage/anyroute
      path: '/:language(\\w{2,2}\\b)/*',
      redirect: to => {
        return `/${getValidLanguage()}/${to.params[0]}`
      }
    },
    {
      // matches /anyroute (if not found) and redirects it to /validlanguage/anyroute
      path: '*',
      redirect: to => {
        return `/${getValidLanguage()}${to.path}`
      }
    }
  ])
}

export default routes
