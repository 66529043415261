<template>
  <div class="header">
    <div class="bg"></div>
    <router-link :to="{ name: 'Home' }" class="logo-link">
      <div v-if="!$root.platform.mobile" class="letinte-container">
        <img :src="letinteLogo" class="letinte-logo mr-3"> <span v-if="!$root.platform.mobile" class="uppercase c-brown mb-3">since 2013</span><!--<img :src="logo" class="logo">-->
      </div>

      <div v-if="$root.platform.mobile" class="letinte-container">
        <img :src="letinteLogo" class="letinte-logo mr-3">
      </div>
      <span v-if="$root.platform.mobile" class="uppercase">since 2013</span>
      <!--<img v-if="$root.platform.mobile" :src="logo" class="logo">-->
    </router-link>
    <div class="lang" v-if="!$root.platform.mobile">
      <!--<span class="mr-3 bold">
        <a href="https://en.stylem.co.jp/" target="_blank">STYLEM</a>
      </span>-->
      <span class="mr-3">
        <a href="https://www.instagram.com/letinte_official/" target="_blank">
          <img class="mb-n1 instagram" :src="instagram">
        </a>
      </span>

      <span><router-link :class="{'active': $route.params.language === 'en'}" :to="{ params: {language: 'en' }}">EN</router-link> | </span>
      <span><router-link :class="{'active': $route.params.language === 'it'}" :to="{ params: {language: 'it' }}">IT</router-link> | </span>
      <span><router-link :class="{'active': $route.params.language === 'jp'}" :to="{ params: {language: 'jp' }}">JP</router-link></span>
    </div>
    <transition name="slide">
      <div v-show="menuOpen" class="menu uppercase">
        <v-row fill-height align="center" class="fill-height px-16">
          <v-col>
            <div v-if="$root.platform.mobile">
              <div class="mb-6">
                <!--<a href="https://en.stylem.co.jp/" target="_blank">
                  STYLEM
                </a>-->
              </div>
              <div class="mb-6">
                <a href="https://www.instagram.com/letinte_official/" target="_blank">
                  <img class="mb-n1 instagram" :src="instagram"> INSTAGRAM
                </a>
              </div>
              <div class="mb-6">
                <span><router-link :class="{'active': $route.params.language === 'en'}" :to="{ params: {language: 'en' }}">ENG</router-link> | </span>
                <span><router-link :class="{'active': $route.params.language === 'it'}" :to="{ params: {language: 'it' }}">IT</router-link> | </span>
                <span><router-link :class="{'active': $route.params.language === 'jp'}" :to="{ params: {language: 'jp' }}">JP</router-link></span>
              </div>
              <div class="medium-line"></div>
            </div>
            <h2 @click="menuOpen = false"><router-link :to="{ name: 'Collection' }">Collection</router-link></h2>
            <h2 @click="menuOpen = false"><router-link :to="{ name: 'Identity' }">Identity</router-link></h2>
            <h2 @click="menuOpen = false"><router-link :to="{ name: 'Company' }">Company</router-link></h2>
            <h2 @click="menuOpen = false"><router-link :to="{ name: 'Contact' }">Contact</router-link></h2>
            <h2 @click="menuOpen = false"><router-link :to="{ name: 'Certification' }">Certification</router-link></h2>
          </v-col>
        </v-row>
      </div>
    </transition>
    <div class="hamburger" :class="{'close': menuOpen}" @click="menuOpen = !menuOpen">
      <!--<span></span>
      <span></span>
      <span></span>-->
      {{menuOpen ? 'CLOSE' : 'MENU'}}
    </div>
  </div>
</template>

<script>
import letinteLogo from '@/assets/img/le-tinte-logo-brown.png'
import instagram from '@/assets/img/instagram.png'
import logo from '@/assets/img/stylem-italia-logo-nosrl.png'
import stylemlogo from '@/assets/img/stylem-logo.png'
export default {
  name: 'Header',

  data () {
    return {
      logo,
      stylemlogo,
      instagram,
      letinteLogo,
      menuOpen: false
    }
  }
}
</script>

<style lang="scss" scoped>

.active {
  opacity: 1 !important;
  font-weight: 600;
}

.white {
  .menu,
  .bg {
    background-color: #fff;
  }

  .logo-stylem,
  .letinte-logo,
  .instagram,
  .logo {
    filter: invert(1);
  }

  .hamburger span {
    background-color: $c-black;
  }
}

.letinte-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;
}

.logo-link {
  position: fixed;
  top: 15px;
  left: 50%;
  vertical-align: middle;
  overflow: visible;
  transform: translateX(-50%);

  @include breakpoint('xs-only') {
    top: 10px;
  }

  span {
    margin-left: 5px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    display: inline-block;

    @include breakpoint('xs-only') {
      font-size: 10px;
    }
  }
}

.logo-stylem {
  height: 15px;
  margin-right: 5px;
}

.logo {
  height: 25px;
  margin-left: 10px;

  @include breakpoint('xs-only') {
    height: 18px;
    margin-left: 0;
    margin-top: 5px;
  }
}

.letinte-logo {
  height: 60px;
  display: inline-block;

  @include breakpoint('xs-only') {
    height: 30px;
  }
}

.menu {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $c-black;

  h2 {
    margin: 20px 0;
    letter-spacing: 3px;
    opacity: 0.5;
    position: relative;
    transition: opacity 0.5s, transform 0.5s;

    &::before {
      content: '';
      width: 0;
      height: 1px;
      background-color: $c-white;
      position: absolute;
      top: 50%;
      left: -30px;
      transition: width 0.5s;
    }

    &:hover {
      opacity: 1;
      transform: translateX(30px);

      &::before {
        width: 20px;
      }
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.bg {
  content: '';
  background-color: $c-black;
  width: 100vw;
  height: 90px;
}

.instagram {
  width: 20px;
  height: 20px;
}

.lang {
  position: fixed;
  top: 30px;
  right: 20px;
  text-align: right;
  vertical-align: middle;

  span {
    display: inline-block;
  }

  p {
    line-height: 1;
    text-decoration: underline;
  }

  a {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

.hamburger {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  top: 30px;
  left: 20px;
}

.hamburger span {
  background-color: $c-white;
  width: 2em;
  height: 0.2em;
  margin: 0.26em 0;
  display: block;
  transition: all 0.4s ease;
  transform-origin: 0 0;
}

.close span:nth-child(1) {
  transform: rotate(45deg) translate(1px, -1px);
}

.close span:nth-child(2) {
  transform: scaleX(0);
}

.close span:nth-child(3) {
  transform: rotate(-45deg) translate(1px, 0);
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-out;
}

.slide-enter,
.slide-leave-active {
  transform: translateX(100vw);
}
</style>
